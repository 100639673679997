import React, { useContext } from "react";
import Login from "./components/Login";
import MainScreen from "./components/MainScreen";
import { WalletContext } from "./context/WalletContext";

function App() {
  const { walletAddress } = useContext(WalletContext);

  return (
    <div className="App">
      {walletAddress ? (
        // Show MainScreen if wallet is connected
        <MainScreen />
      ) : (
        // Show Login if no wallet is connected
        <Login />
      )}
    </div>
  );
}

export default App;
