import React from "react";
import "./styles/Card.css";

const Card = ({ card, onSelect, isSelected }) => {
  return (
    <div
      className={`card ${card.owned ? "owned" : "not-owned"} ${
        isSelected ? "selected" : ""
      }`}
      onClick={onSelect}
    >
      <div className="card-inner">
        <div className="card-front">
          {card.owned ? (
            <>
              <img src={`assets/PNG/${card.id}.png`} alt={`Card ${card.id}`} />
              {card.balance > 1 && (
                <span className="badge">{card.balance}</span>
              )}
            </>
          ) : (
            <div className="card-back">
              <img src={`assets/Card Backs/${card.id}.png`} alt={`Card Back`} />
            </div>
          )}
        </div>

        {card.owned && (
          <div className="card-animation">
            <video
              src={`assets\\MP4\\${card.id}.mp4`}
              className="card-animation"
              playsInline
              loop
              muted
              autoPlay
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
