import { useContext, useState } from "react";
import { WalletContext } from "../context/WalletContext";
import "./styles/LoginScreen.css";

const Login = () => {
  const { connectWallet, walletAddress } = useContext(WalletContext);
  const [loading, setLoading] = useState(false);

  const handleConnect = async () => {
    setLoading(true);
    try {
      await connectWallet();
    } catch (error) {
      console.error("Error connecting wallet:", error);
    }
    setLoading(false);
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <h1>Welcome</h1>
        <div className="loginInfo">
          <p>
            Mythical Trading Cards is a limited series of digital collectibles
            inspired by legendary creatures like Yeti, Werewolf, Dragon, and
            more. Each pack includes 3 NFTs, randomly assigned and drawn as
            Common 99%, Rare 1%, or Legendary 0.01%. With unique rarity tiers,
            collectors can also burn 9 Common cards for a Rare or 3 Rare for a
            Legendary. Once all 25 legendaries are found the minting will end.
            Enjoy this creative way to own a piece of mythological history.
          </p>
          <p>
            Disclaimer: This collection is purely artistic and holds no
            intrinsic financial value. Participation is for enjoyment and
            collectible purposes only.
          </p>
        </div>
      </div>
      <button onClick={handleConnect} disabled={loading}>
        {loading
          ? "Connecting..."
          : walletAddress
          ? "Connected"
          : "Connect Wallet"}
      </button>
    </div>
  );
};

export default Login;
