import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import "./styles/MintPack.css";
import { loadOwnedCards } from "./loadOwnedCards";
import { WalletContext } from "../context/WalletContext";
import { ethers } from "ethers";
import { loadPrice } from "./packprice";

const CONTRACT_ABI = [
  {
    inputs: [],
    name: "mintPack",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "packPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getLastMintedIds",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "checkSpecialEligibility",
    type: "function",
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    inputs: [],
    name: "mintSpecialId",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "uri",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const MintPack = ({ contractAddress, setOwnedCards }) => {
  const { walletAddress, signer } = useContext(WalletContext);
  const [isMinting, setIsMinting] = useState(false);
  const [isSpecialMinting, setIsSpecialMinting] = useState(false);
  const [lastMintedIds, setLastMintedIds] = useState([]);
  const [packOpened, setPackOpened] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const [specialMetadata, setSpecialMetadata] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const packAnimationRef = useRef(null);
  const idAnimationRefs = useRef([]);
  const [mintText, setMintText] = useState("Mint Pack");
  const [price, setPrice] = useState(null);
  const priceinfo = `${price} POL`;

  // Memoized checkEligibility function using useCallback
  const checkEligibility = useCallback(async () => {
    if (!signer || !contractAddress) return;

    try {
      const contract = new ethers.Contract(
        contractAddress,
        CONTRACT_ABI,
        signer
      );
      const eligibility = await contract.checkSpecialEligibility(walletAddress);
      setIsEligible(eligibility);
    } catch (error) {
      console.error("Error fetching eligibility gift:", error.message || error);
    }
  }, [contractAddress, signer, walletAddress]);

  useEffect(() => {
    if (contractAddress && signer) {
      loadPrice(contractAddress, signer, setPrice);
      checkEligibility(); // Call the function
    }
  }, [contractAddress, signer, checkEligibility]);

  const refreshOwnedCards = async () => {
    try {
      await loadOwnedCards(
        walletAddress,
        contractAddress,
        setOwnedCards,
        signer
      );
    } catch (error) {
      console.error("Error refreshing owned cards:", error);
    }
  };

  const handleMintPack = async () => {
    if (!signer) return alert("Please connect your wallet first.");

    try {
      setIsMinting(true);
      setPackOpened(false);

      const contract = new ethers.Contract(
        contractAddress,
        CONTRACT_ABI,
        signer
      );
      const packPrice = await contract.packPrice();

      const tx = await contract.mintPack({
        value: packPrice,
        gasLimit: 3000000, // estimateGas()
      });

      const receipt = await tx.wait();
      if (receipt.status === 1) {
        alert("Mint successful!");

        const lastMints = await contract.getLastMintedIds(walletAddress);
        const mintedIds = lastMints.map((id) => Number(id));
        setLastMintedIds(mintedIds);
        console.log("minted ids", mintedIds);

        startAnimations();

        const eligibility = await contract.checkSpecialEligibility(
          walletAddress
        );
        setIsEligible(eligibility);

        await refreshOwnedCards();
      } else {
        alert("Transaction failed. Please try again.");
      }
    } catch (error) {
      console.error("Error minting pack:", error);
    } finally {
      setIsMinting(false);
    }
  };

  const fetchMetadata = async (uri, tokenId) => {
    try {
      const resolvedUri = uri.replace("{id}", tokenId);
      const response = await fetch(resolvedUri);
      const metadata = await response.json();
      return metadata;
    } catch (error) {
      console.error("Error fetching metadata:", error);
      return null;
    }
  };

  const handleMintSpecial = async () => {
    if (!signer || !isEligible) return;

    try {
      setIsSpecialMinting(true);

      const contract = new ethers.Contract(
        contractAddress,
        CONTRACT_ABI,
        signer
      );
      const tx = await contract.mintSpecialId({ gasLimit: 3000000 });
      const receipt = await tx.wait();

      if (receipt.status === 1) {
        alert("Special NFT minted successfully!");
        await refreshOwnedCards();

        const lastMintedIds = await contract.getLastMintedIds(walletAddress);
        const lastMintedId = lastMintedIds[3];
        const uri = await contract.uri(lastMintedId);
        const metadata = await fetchMetadata(uri, lastMintedId);

        setSpecialMetadata(metadata);
        setShowPopup(true);

        const eligibility = await contract.checkSpecialEligibility(
          walletAddress
        );
        setIsEligible(eligibility);
      } else {
        alert("Special mint transaction failed.");
      }
    } catch (error) {
      console.error("Error minting special NFT:", error);
    } finally {
      setIsSpecialMinting(false);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const playCardAnimation = useCallback(
    (index) => {
      if (index < lastMintedIds.length) {
        const animation = idAnimationRefs.current[index];
        if (animation && animation.paused) {
          animation.currentTime = 0;
          animation.play();
          animation.onended = () => {
            playCardAnimation(index + 1);
          };
        }
      }
    },
    [lastMintedIds]
  );

  const startAnimations = useCallback(() => {
    if (packAnimationRef.current) {
      packAnimationRef.current.play();
      packAnimationRef.current.onended = () => {
        setPackOpened(true);
        playCardAnimation(0);
      };
    }
  }, [playCardAnimation]);

  useEffect(() => {
    if (packOpened && lastMintedIds.length > 0) {
      playCardAnimation(0);
    }
  }, [packOpened, lastMintedIds, playCardAnimation]);

  useEffect(() => {
    if (lastMintedIds.length > 0) {
      startAnimations();
    }
  }, [lastMintedIds, startAnimations]);

  return (
    <div className="mint-section">
      <div className="animation-container">
        {!packOpened && (
          <video
            ref={packAnimationRef}
            src="/assets/openPack.mp4"
            className="pack-opening"
            muted
            playsInline
            preload="auto"
          />
        )}

        {showPopup && specialMetadata && (
          <div className="popup-overlay" onClick={closePopup}>
            <div className="popup-content">
              {specialMetadata.animation_url ? (
                <video controls autoPlay loop muted>
                  <source
                    src={specialMetadata.animation_url}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={specialMetadata.image} alt="Special Minted NFT" />
              )}
              <button onClick={closePopup}>Close</button>
            </div>
          </div>
        )}

        {packOpened && (
          <div className="id-animation">
            {lastMintedIds.map((id, index) => (
              <video
                ref={(el) => (idAnimationRefs.current[index] = el)}
                key={`${id}-${index}`}
                src={`/assets/card_lay/${id}.mp4`}
                className="id-animation"
                muted
                playsInline
                preload="auto"
              />
            ))}
          </div>
        )}
      </div>
      <div className="mint-gift-cont">
        <button
          onClick={handleMintPack}
          disabled={isMinting}
          onMouseEnter={() => setMintText(priceinfo)}
          onMouseLeave={() => setMintText("Mint Pack")}
        >
          {isMinting ? "Minting..." : mintText}
        </button>

        {isEligible && (
          <div>
            <button onClick={handleMintSpecial} disabled={isSpecialMinting}>
              {isSpecialMinting ? "Minting Gift..." : "Mint Gift"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MintPack;
