import { ethers } from "ethers";

const CONTRACT_ABI = [
  {
    inputs: [
      {
        internalType: "address[]",
        name: "accounts",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "ids",
        type: "uint256[]",
      },
    ],
    name: "balanceOfBatch",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const loadOwnedCards = async (
  walletAddress,
  contractAddress,
  setOwnedCards,
  signer
) => {
  if (!walletAddress || !signer) return;

  try {
    const contract = new ethers.Contract(contractAddress, CONTRACT_ABI, signer);
    const accounts = Array(15).fill(walletAddress);
    const ids = Array.from({ length: 15 }, (_, i) => i + 1);

    const balances = await contract.balanceOfBatch(accounts, ids);
    const mappedCards = balances.map((balance, index) => ({
      id: ids[index],
      balance: Number(balance),
      owned: Number(balance) > 0,
    }));
    setOwnedCards(mappedCards);
  } catch (error) {
    console.error("Error fetching balances:", error);
  }
};
