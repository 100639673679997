import React, { useContext, useEffect, useState } from "react";
import { WalletContext } from "../context/WalletContext";
import Card from "./Card";
import MintPack from "./MintPack";
import BurnCards from "./Burn";
import { loadOwnedCards } from "./loadOwnedCards";
import "./styles/MainScreen.css";

const contractAddress = "0x6C09AA60CcA8DCbaDB7235Da7ef22870e02CA8C4";

const MainScreen = () => {
  const { walletAddress, signer, disconnectWallet } = useContext(WalletContext);
  const [ownedCards, setOwnedCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);

  useEffect(() => {
    if (walletAddress) {
      loadOwnedCards(walletAddress, contractAddress, setOwnedCards, signer);
    }
  }, [walletAddress, signer]);

  useEffect(() => {
    console.log("Updated owned cards in MainScreen:", ownedCards);
  }, [ownedCards]);

  function handleCardSelect(id) {
    setSelectedCards((prevSelected) => (prevSelected.includes(id) ? [] : [id]));
  }

  return (
    <div className="main-screen">
      <h1>Welcome</h1>
      <h6>{walletAddress}</h6>
      <div className="button-container">
        <button onClick={disconnectWallet} disabled={!walletAddress}>
          Disconnect Wallet
        </button>
      </div>

      <div>
        <MintPack
          walletAddress={walletAddress}
          contractAddress={contractAddress}
          setOwnedCards={setOwnedCards}
        />
      </div>

      <div className="your-collection">
        <h5>Your Collection</h5>
        <div className="collection-grid">
          {ownedCards.length > 0 ? (
            ownedCards.map((card) => (
              <Card
                key={card.id}
                card={card}
                onSelect={() => handleCardSelect(card.id)}
                isSelected={selectedCards.includes(card.id)}
              />
            ))
          ) : (
            <p>No cards found in your wallet.</p>
          )}
        </div>
        <div className="burn-button-container">
          <BurnCards
            selectedCards={selectedCards}
            ownedCards={ownedCards}
            contractAddress={contractAddress}
            setOwnedCards={setOwnedCards}
            walletAddress={walletAddress}
          />
        </div>
      </div>
    </div>
  );
};

export default MainScreen;
