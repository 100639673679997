import { ethers, formatEther } from "ethers";

const CONTRACT_ABI = [
  {
    inputs: [],
    name: "packPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const loadPrice = async (contractAddress, signer, setPrice) => {
  if (!signer || !contractAddress) return;

  try {
    const contract = new ethers.Contract(contractAddress, CONTRACT_ABI, signer);
    const packPrice = await contract.packPrice();

    const priceInEther = formatEther(packPrice);
    setPrice(Number(priceInEther));
  } catch (error) {
    console.error("Error fetching pack price:", error.message || error);
  }
};
