import React, { useState, useContext } from "react";
import "./styles/Burn.css";
import { loadOwnedCards } from "./loadOwnedCards";
import { WalletContext } from "../context/WalletContext";
import { ethers } from "ethers";

const CONTRACT_ABI = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "cardId",
        type: "uint256",
      },
    ],
    name: "burnForRares",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "cardId",
        type: "uint256",
      },
    ],
    name: "burnForLegendary",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const Burn = ({
  selectedCards,
  ownedCards,
  setOwnedCards,
  contractAddress,
}) => {
  const { signer, walletAddress } = useContext(WalletContext);
  const [rareText, setRareText] = useState("Burn for Rare");
  const [legendaryText, setLegendaryText] = useState("Burn for Legendary");

  const refreshOwnedCards = async () => {
    await loadOwnedCards(walletAddress, contractAddress, setOwnedCards, signer);
  };

  const handleBurn = async (burnType) => {
    if (!signer) {
      alert("Please connect your wallet first.");
      return;
    }

    try {
      if (selectedCards.length === 0) {
        alert("Please select cards to burn.");
        return;
      }

      const cardId = selectedCards[0];
      const card = ownedCards.find((card) => card.id === cardId);

      if (!card) {
        alert("Selected card not found in owned cards.");
        return;
      }

      const balance = card.balance;

      const contract = new ethers.Contract(
        contractAddress,
        CONTRACT_ABI,
        signer
      );
      let tx;

      if (burnType === "rare") {
        if (balance >= 9) {
          tx = await contract.burnForRares(cardId);
        } else {
          alert("You need at least 9 of this card to burn for a rare!");
          return;
        }
      } else if (burnType === "legendary") {
        if (balance >= 3) {
          tx = await contract.burnForLegendary(cardId);
        } else {
          alert("You need at least 3 of this card to burn for a legendary!");
          return;
        }
      } else {
        alert("Invalid burn type.");
        return;
      }

      console.log("Transaction:", tx);
      await tx.wait(); // Wait for the transaction to be mined
      alert("Burning successful!");

      // Refresh owned cards after successful burn
      await refreshOwnedCards();
      console.log("Updated owned cards after burn");
    } catch (error) {
      console.error("Error in handleBurn:", error);
      alert("An error occurred during the burning process.");
    }
  };

  return (
    <div className="burn-section">
      <button
        onClick={() => handleBurn("rare")}
        onMouseEnter={() => setRareText("Burn 9 Common")}
        onMouseLeave={() => setRareText("Burn for Rare")}
      >
        {rareText}
      </button>
      <button
        onClick={() => handleBurn("legendary")}
        onMouseEnter={() => setLegendaryText("Burn 3 Rares")}
        onMouseLeave={() => setLegendaryText("Burn for Legend")}
      >
        {legendaryText}
      </button>
    </div>
  );
};

export default Burn;
